.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  word-break: break-word;
}

.tag {
  margin-left: 1rem;
  height: 22px;
}

@primary-color: rgb(25,61,113);