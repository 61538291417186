/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.thumbnail {
  height: 60px;
  width: 60px;
  align-self: stretch;
  margin: 0 0.7rem 0.5rem 0;
  flex: 0 0 auto;
}
.icon {
  font-size: 60px;
}
