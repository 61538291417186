/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.avatarContainer {
  position: relative;
  display: flex;
}
.avatarContainer.clickable {
  cursor: pointer;
}
.hasOwner {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0.5em;
  height: 0.5em;
  border: solid 2px #162740;
  background-color: #ffffff;
  border-radius: 50%;
}
.hasOwner.withUser {
  background-color: #162740;
}
.popoverContent > *:not(:last-child) {
  margin-bottom: 0.3rem;
}
