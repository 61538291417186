.modalWindow {
  position: relative;
  background-color: rgba(255, 255, 255);
  flex: 1;
  overflow: auto;
  composes: scrollbar from global;
}

.settingsSection {
  padding: 0 0.8rem;
  margin: 0 0 1rem 0;
  flex: 1;
}

.buttonGroup {
  justify-content: flex-end;
  display: flex;
  background-color: #d8d8d8;
  padding: 0.5rem 1rem;
}

.modalWrap {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  z-index: 120;
  position: relative;
  padding: 0;
}

.emptyWrap {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@primary-color: rgb(25,61,113);