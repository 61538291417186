.dragCard {
  margin: 0.5rem 0 !important;
}

.dragIcon {
  cursor: 'move';
}

.empty {
  margin: 1rem auto;
}

@primary-color: rgb(25,61,113);