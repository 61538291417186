.mainPanel {
  padding-right: 0.5rem;
  overflow-x: hidden !important;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.projectDetail {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@primary-color: rgb(25,61,113);