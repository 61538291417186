/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container {
  padding: 0.6rem;
}
.container.active {
  background: #e5e5e5;
}
.headline {
  display: flex;
  justify-content: space-between;
}
.title {
  flex: 1;
}
.title :global(.anticon) {
  margin-right: 1rem;
}
.highlight {
  font-weight: 600;
}
.dateline {
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
}
.createDate {
  color: #999999;
  text-align: right;
}
