/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: solid 1px #e8e8e8;
}
.header {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
  align-items: center;
  flex-wrap: wrap;
}
.header > :last-child {
  margin-left: auto;
}
.input {
  flex: 1;
  min-width: 0;
}
.inputArea {
  flex: 1;
  min-width: 0;
}
.notifyUserContainer {
  font-size: 0.8rem;
  font-weight: 600;
  color: #666666;
  margin: 0.25rem 0;
}
.attachmentWarning {
  padding: 0 0.5rem;
}
