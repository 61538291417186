/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.comment {
  margin: 0 0 1.5rem 0;
  margin-right: auto;
  border-radius: 0.25rem;
  color: #000000;
  background: #ffffff;
  width: 100%;
}
.message {
  margin: 0;
  padding: 0;
  color: #000000;
  white-space: pre-wrap;
  composes: scrollbar from global;
  max-height: 200px;
  overflow: auto;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap-reverse;
  gap: 0 2rem;
  align-items: center;
}
.subInfo {
  font-weight: 600;
  font-size: 0.9em;
  color: #666666;
  cursor: default;
}
.menuContainer {
  margin-left: auto;
}
.closeEdit {
  margin-right: 0.3rem;
}
.commentInput {
  margin: 0.3rem 0 0;
  display: flex;
  justify-content: space-between;
}
.commentInput > textarea {
  margin-right: 0.5rem;
}
.newNote {
  margin-right: 0.5rem;
}
.notePanel {
  max-height: 400px;
  margin-bottom: 1rem;
}
.hubIcon {
  max-width: 100%;
  max-height: 100%;
  width: 1em;
}
.headerGap {
  gap: 1rem;
}
.noteOrder {
  font-weight: bold;
}
