.group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.button {
  margin-right: 1rem;
}

@primary-color: rgb(25,61,113);