/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.charts {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: auto;
}
.middle {
  width: 20px;
}
.chart {
  display: inline-block;
  border: 1px solid #c0c0c0;
  flex: 1;
  width: 60px;
  max-width: 48%;
}
.empty {
  margin-bottom: 50px;
}
@media only screen and (max-width: 500px) {
  .charts {
    display: none;
  }
}
