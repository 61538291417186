.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 48px;
  font-size: 32px;
  line-height: 0;
  margin: auto;
  cursor: pointer;
  user-select: none;
  background: #dbdbdb;
  z-index: 1;

  & > :global(.anticon) {
    margin: 8px;
  }

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
}

.spinBox {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: none !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 1000;
}

.geneButton {
  max-width: fit-content;
  margin: 0 auto;
}

@primary-color: rgb(25,61,113);