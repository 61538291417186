.settings {
  width: 400px;

  :global(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
    margin-left: 0;
    display: flex;
  }
}

.directorySelect {
  min-width: 200px;
  max-width: 500px;
}

.sizeSlider {
  width: 100px;
}

@primary-color: rgb(25,61,113);