/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.listItem {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  border-radius: 0.25rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  transition: background-color 0.3s ease-in-out, outline-color 0.3s ease-in-out;
  outline-width: 0;
  outline-offset: -2px;
  outline-color: transparent;
}
.listItem.selectable {
  cursor: pointer;
}
.listItem.selected:not(.disabled) {
  outline: 2px solid #193d71;
}
.listItem.disabled {
  background: #e8e8e8;
  color: #666666;
}
.listItem.selectable:not(.disabled):hover {
  background-color: #dddddd;
}
.listItemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem 1rem;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  flex-wrap: wrap;
}
.wrap .listItemContent {
  flex-wrap: wrap;
}
.listItemBody {
  flex: 1 1 auto;
  overflow: hidden;
}
.listItemInput {
  margin-left: auto;
}
.listItemIcon {
  width: 2rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listItemHeader {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  flex: 1;
  min-width: 120px;
  overflow-wrap: anywhere;
}
.listItemHeader .listItemTitle {
  font-weight: bold;
  line-height: 1.2em;
}
.listItemHeader p {
  line-height: 1.2rem;
  margin: 0;
}
.contentBody {
  display: flex;
}
.listItemActions {
  flex: 0 0 auto;
  align-self: center;
  display: none;
}
.listItemActionButton {
  margin-right: 8px;
}
.description {
  white-space: pre-wrap;
}
