/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.activityRow {
  border-radius: 0.25rem;
  background-color: #ffffff;
  transition: background-color 0.3s;
  padding: 0.5rem;
  margin-bottom: 0.2rem;
}
.activityRow:hover {
  background-color: #f5f5f5;
}
