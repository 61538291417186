/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.freeDay {
  background-color: #b3b3b3;
}
.danger {
  outline: 2px solid #fa541c;
  outline-offset: -2px;
}
