.listItem {
  box-sizing: border-box;
}

.hbox {
  margin-bottom: 8px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  & .item {
    margin-bottom: 0;
  }

  & .name {
    min-width: 150px;
    flex: 1 1 auto;
  }

  & .description {
    flex: 1 0 auto;
    width: 100%;
  }

  & .buttons {
    display: flex;
    width: 100%;
    margin-top: 8px;
    justify-content: flex-end;
  }
}

@primary-color: rgb(25,61,113);