/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.wrapper {
  padding: 2px 0;
  height: 100%;
  min-width: 300px;
}
.row {
  border-radius: 5px;
  background: #ffffff;
}
.row.active {
  outline: 2px solid #193d71;
  outline-offset: -2px;
  background: #ffffff;
}
.row.invalidTemplate {
  border: solid #fba8c2 10px;
  background-color: #fba8c2;
}
