.dirsSection {
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  composes: scrollbar from global;
  height: 100%;
}

.spinBoxIndicator {
  width: 300px;
}

.errorList {
  margin-top: 0.5rem;
}

.errorList p {
  margin-bottom: 0;
}

.errorLabel {
  margin-top: 0.5rem;
}

.wrapperDiv {
  flex-grow: 1;
  width: 100%;
}

@primary-color: rgb(25,61,113);