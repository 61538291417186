/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.formWrapper {
  display: flex;
  flex-wrap: nowrap;
}
.generalConfig {
  margin-right: 1rem;
  padding-right: 1rem;
  width: 400px;
  border-right: 1px solid #e5eced;
}
@media only screen and (max-width: 900px) {
  .formWrapper {
    display: block;
  }
  .generalConfig {
    width: 100%;
    border-right: none;
  }
}
