/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container {
  display: grid;
  border-top: solid 1px #e8e8e8;
}
.container .items {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.container .items > * + * {
  border-top: solid 1px #e8e8e8;
}
