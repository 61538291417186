.title.title {
  margin: 0 0 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  min-height: 2rem;

  .headline {
    margin: 0;
    font-weight: 600;
    line-height: 1em;
    font-size: 1rem;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 95%;
  }
}

.content {
  margin: 0;
  display: flex;
  flex: 1;
  overflow-x: auto;
  composes: scrollbar from global;
}

@media (max-width: 1000px) {
  .title.title {
    .headline {
      font-size: 1rem;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 600px) {
  .title.title {
    margin: 0 0.5rem 0.5rem 0.5rem;

    .headline {
      font-size: 13px;
    }
  }

  .content {
    margin: 0 0 0 0;
  }
}

@primary-color: rgb(25,61,113);