.resizer {
  position: absolute;
  cursor: ew-resize;
  width: 1rem;
  height: 100%;
  min-height: 200px;
  z-index: 10;
  right: -1rem;
  top: 0;
  user-select: none;
  -webkit-user-select: none;
  background: transparent;
  transition: background 0.5s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #cccccc;
  font-size: 2rem;

  &:hover {
    color: #ffffff;
    background: #cccccc;
  }
}

@primary-color: rgb(25,61,113);