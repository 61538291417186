.icon {
  padding: 4px;
  vertical-align: middle;
}

.noMargin {
  margin: 0;
}

.keyTagsGroup {
  min-width: 135px;
  display: inline-block;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}

.keyShortcutItem {
  margin-bottom: 0.5rem;
  display: flex;
}

.listStyleTypeNone {
  list-style-type: none;
}

.stretch {
  flex: 1;
}

@primary-color: rgb(25,61,113);