/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.flexTabs {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
.flexTabs > :global(.ant-tabs-nav) {
  margin-bottom: 0;
}
.flexTabs > :global(.ant-tabs-nav)::before {
  border-color: #e5e5e5;
}
.flexTabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab) {
  margin-left: 1.5em;
}
.flexTabs > :global(.ant-tabs-content-holder) {
  display: flex;
}
.flexTabs > :global(.ant-tabs-content-holder) > :global(.ant-tabs-content) {
  display: flex;
  flex: 1;
}
.flexTabs > :global(.ant-tabs-content-holder) > :global(.ant-tabs-content) > * {
  max-width: 100%;
  flex: 1;
}
