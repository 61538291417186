/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.wrapper {
  height: 54px;
  min-width: 500px;
}
.row {
  height: 100%;
  padding: 3px 6px 3px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 0;
  border-bottom: solid 1px #e6e8f1;
}
.row.selected {
  background-color: #f1f1f1;
}
.row:hover {
  background: #e0e0e0;
}
.row:hover .checkbox:before {
  display: flex;
}
.row .thumbnail {
  width: 55px;
  margin-right: 1rem;
  flex: 0 0 auto;
  cursor: pointer;
  align-self: stretch;
}
.row .thumbnail :global(.anticon) {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row .main {
  flex: 1 1 auto;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  display: flex;
}
.row .main .nameLabels {
  display: flex;
  width: 100%;
  font-weight: 700;
}
.row .main .nameLabels .name {
  font-size: 16px;
  line-height: 22px;
  margin-right: 8px;
  flex: 0 1 auto;
  white-space: nowrap;
  min-width: 50px;
  text-overflow: ellipsis;
}
.row .main .nameLabels .labels {
  margin-right: 8px;
  flex: 1 2 auto;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 4px;
}
.row .main .description {
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.5rem;
  display: flex;
  width: 100%;
  cursor: pointer;
}
.row .date {
  margin-right: 8px;
  flex: 0 0 auto;
}
.row .author {
  margin-right: 8px;
  flex: 0 0 auto;
}
.tag {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 0 auto;
  max-width: 130px;
  min-width: 0;
}
.restore {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nameRow {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  max-width: 100%;
  gap: 0.5rem;
}
