/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.noLink {
  color: #999999 !important;
}
.noLink:hover {
  color: #999999 !important;
  cursor: default;
}
.menu :global(.ant-menu-submenu-active) {
  color: #fff !important;
}
.menu :global(.ant-menu-item) {
  margin: 0;
}
.menu :global(.ant-menu:not(.ant-menu-inline-collapsed)) .subMenu {
  padding-left: 3rem !important;
}
.menu :global(.ant-menu-sub) {
  background: #162740 !important;
}
.menu :global(.ant-menu-item-selected) {
  background: #193d71 !important;
  color: #ffffff !important;
  width: auto;
}
.sider {
  flex: 1;
  overflow: auto;
  background: #162740;
  composes: scrollbar from global;
}
.sider :global(.ant-layout-sider-children) {
  display: flex;
  flex-direction: column;
}
.menu {
  flex: auto;
  background: transparent !important;
}
.menu :global(.ant-menu-item:not(.ant-menu-item-disabled)):hover {
  text-decoration: underline !important;
}
.menuBottom {
  background: transparent !important;
}
.menuItemCompany {
  display: flex;
  align-items: center;
  font-size: 12px !important;
  margin: 0.5rem 1rem;
}
.hubLogo {
  margin: 1rem;
}
.spacer {
  height: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-height: 0;
  min-height: 0;
  border-bottom: solid 2px #193d71;
}
