.esticonDbSelect {
  width: 100%;
}

.permissionTree {
  & :global(.ant-tree-treenode),
  & :global(.ant-tree-node-content-wrapper) {
    width: 100%;
  }
}

.permissionName {
  flex-grow: 1;
}

.permission {
  width: 200px;
}

@primary-color: rgb(25,61,113);