.roleSelect {
  width: 100%;
  height: auto;

  :global(.ant-select-selector.ant-select-selector) {
    height: auto;
  }
  :global(.ant-select-selection-search) {
    display: flex;
    align-items: center;
  }
  :global(.ant-select-selection-item) {
    display: flex;
    align-items: center;
  }
}

@primary-color: rgb(25,61,113);