/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.comment {
  margin: 0 3rem 0.5rem 0;
  margin-right: auto;
  max-width: 90%;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  color: #000000;
  background: #ffffff;
}
.comment.editing {
  width: 90%;
}
.messageInnerWrap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.messageHeader {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.message {
  margin: 0;
  padding: 0;
  color: #000000;
  white-space: pre-wrap;
  composes: scrollbar from global;
  max-height: 200px;
  overflow: auto;
}
.myComment {
  margin-right: 0;
  margin-left: auto;
  background: #e8ebfa;
}
.header {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 0 2rem;
  align-items: center;
}
.author {
  font-weight: 600;
  word-break: break-all;
}
.subInfo {
  font-weight: 600;
  font-size: 0.9em;
  color: #666666;
  cursor: default;
}
.menuContainer {
  margin-left: auto;
}
.closeEdit {
  margin-right: 0.3rem;
}
.discardedNote {
  margin: 0.2rem auto 0.2rem 0;
  background-color: lightgray;
  display: inline-block;
  font-style: italic;
  border-radius: 0.2rem;
  padding: 0 1rem;
}
.commentInput {
  margin: 0 0;
}
.notifiedUsers {
  font-size: 0.8rem;
  font-weight: 600;
  color: #666666;
  margin: 0 0 0.25rem 0;
}
