/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.badge {
  padding: 0 5px;
  height: 24px;
  white-space: nowrap;
}
.icon {
  margin-left: 3px;
}
.stateTitle {
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}
.number {
  line-height: 1rem;
  height: 1rem;
}
.workInProgress {
  color: #1890ff;
  background: #e6f7ff;
}
.shared {
  color: #faad14;
  background: #fffbe6;
}
.rejected {
  color: #f5222d;
  background: #fff1f0;
}
.published {
  color: #389e0d;
  background: #f6ffed;
}
.archived {
  color: #9254de;
  background: #f9f0ff;
}
