/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.accordingToSwitch {
  margin-left: 0.25rem;
}
.tableWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
}
.costColumn {
  text-align: right;
  overflow: visible;
  position: relative;
}
.percentProgressWrap {
  position: absolute;
  left: -2px;
  right: -2px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.percentProgressWrap .percentProgress {
  background: rgba(136, 219, 27, 0.65);
  height: 100%;
  flex: 1;
  width: 0;
  max-height: 1.5em;
}
.percentValue {
  position: relative;
  z-index: 1;
}
.revisionTag {
  display: inline-block;
  font-size: 0.7rem;
  height: 18px;
}
.table {
  font-weight: 500;
}
.table :global .dx-texteditor-input-container {
  height: 30px;
  line-height: 15px;
  display: flex;
}
.table :global .dx-button {
  height: 32px;
}
.table :global .dx-button .dx-button-content {
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.table :global .dx-treelist-header-panel {
  border: 0;
  height: 40px;
}
.table :global .dx-treelist-header-panel .dx-toolbar {
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1rem;
}
.table :global(.dx-selection) {
  outline-offset: -1px;
}
.table :global(.dx-selection) td {
  color: inherit !important;
}
.table :global(.dx-row-focused) td {
  color: inherit !important;
}
.table .GridLevel0,
.table .Projekt {
  background: #2458a3;
  color: #ffffff;
}
.table .GridLevel0 td,
.table .Projekt td {
  color: inherit !important;
}
.table .GridLevel0:global(.dx-treelist-empty-space),
.table .Projekt:global(.dx-treelist-empty-space) {
  color: inherit !important;
}
.table .GridLevel1,
.table .StavebniObjekt {
  background: #ffffff;
}
.table .GridLevel1:nth-of-type(2n + 1),
.table .StavebniObjekt:nth-of-type(2n + 1) {
  background-color: #f9f9f9;
}
.table .GridLevel2,
.table .KonstrukcniPrvek,
.table .StavebniDil {
  background: #e3f4ff;
}
.table .GridLevel2:nth-of-type(2n + 1),
.table .KonstrukcniPrvek:nth-of-type(2n + 1),
.table .StavebniDil:nth-of-type(2n + 1) {
  background-color: #d9f0ff;
}
.table .GridLevel3,
.table .Element,
.table .Polozka,
.table .ZjistovaciProtokol,
.table .PolozkaRozpis {
  background: #c0deff;
}
.table .GridLevel3:nth-of-type(2n + 1),
.table .Element:nth-of-type(2n + 1),
.table .Polozka:nth-of-type(2n + 1),
.table .ZjistovaciProtokol:nth-of-type(2n + 1),
.table .PolozkaRozpis:nth-of-type(2n + 1) {
  background-color: #b6d9ff;
}
.table .GridLevel4 {
  background: #8dc1ff;
}
.table .GridLevel4:nth-of-type(2n + 1) {
  background-color: #83bbff;
}
.table :global(td) {
  background-color: inherit !important;
}
.table :global(.dx-row-focused) td {
  background-color: rgba(55, 255, 0, 0.44) !important;
}
:global(.dx-treelist .dx-row) > td {
  padding: 5px 8px;
}
.budgetWrap {
  overflow: visible !important;
}
.thumbnail {
  width: 45px;
  height: 30px;
  margin: -4px -7px;
  align-self: stretch;
  margin-right: 1rem;
  flex: 0 0 auto;
  cursor: pointer;
}
.thumbnail :global(.anticon) {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumbnail:hover {
  outline: solid 1px #193d71;
}
.withMinHeight {
  min-height: 250px;
}
.overdrawned {
  background-color: #ff4f5e;
}
.overdrawned:nth-of-type(2n + 1) {
  background-color: #ff2b3d;
}
