/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.documentRow {
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  background: #ffffff;
  border-bottom: 1px solid #b3b3b3;
}
.documentRow:not(:last-child) {
  margin-bottom: 0.5rem;
}
.wrap {
  flex-wrap: wrap;
}
