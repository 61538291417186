.tile {
  height: 100%;
}

.drop {
  outline: 2px dashed #808080;
}

.drag {
  opacity: 0.5;
}

@primary-color: rgb(25,61,113);