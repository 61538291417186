/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.filterList {
  display: flex;
  flex-direction: column;
  composes: scrollbar from global;
  overflow: auto;
  flex: 1;
}
.filterList .filterOption {
  padding: 0.5rem 1rem;
  margin: 0;
  display: flex;
  align-items: center;
}
.filterList .filterOption :global(.ant-checkbox) {
  top: 0;
}
.selectCheckbox {
  padding: 0.5rem 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e5e5e5;
}
.selectCheckbox :global(.ant-checkbox) {
  top: 0;
}
.filterDropDownHeader {
  border-bottom: solid 1px #f1f1f1;
  display: flex;
  flex-direction: column;
}
.andOperator {
  padding: 0.5rem 1rem;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.title {
  padding: 6px 16px;
  font-weight: 700;
  max-width: 320px;
}
