/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noPadding {
  padding: 0;
}
.border {
  border-radius: 50%;
  position: absolute;
  bottom: -2px;
  width: auto;
  left: 80%;
  display: flex;
}
.border > * {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  border: solid 1px #ffffff;
  margin-left: -3px;
}
