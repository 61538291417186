/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.listItem {
  cursor: pointer;
  margin-bottom: 4px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  position: relative;
  border-radius: 0.25rem;
  background: #ffffff;
}
.listItem.selected {
  border: 1px solid #193d71;
}
.listItem.selected .listItemActions {
  display: flex;
}
.listItem:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  background: #f1f1f1;
}
.listItem:hover .listItemActions {
  display: flex;
}
.listItemContent {
  display: flex;
}
.listItemBody {
  flex: 1 1 auto;
  overflow: hidden;
}
.listItemActions {
  flex: 0 0 auto;
  align-self: center;
  display: none;
  flex-wrap: wrap;
}
