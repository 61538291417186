/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.sizeSmall {
  width: 18px;
  min-width: 18px;
  height: 18px;
  line-height: 17px;
  font-size: 18px;
  padding: 0;
  background: #999999;
}
.sizeMedium {
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 22px;
  background: #999999;
}
.sizeLarge {
  width: 32px;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  font-size: 30px;
  background: #999999;
}
.extraLarge {
  width: 64px;
  min-width: 64px;
  height: 64px;
  line-height: 60px;
  font-size: 60px;
  background: #999999;
}
