/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.item {
  border: 1px solid #d9d9d9;
  padding: 16px;
}
.item:hover {
  border: 1px solid #193d71;
}
.content {
  display: flex;
  align-items: center;
}
.icon {
  font-size: 32px;
}
.info {
  flex: 1;
  margin: 0 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.name {
  font-size: 16px;
  font-weight: 600;
}
