.container {
  display: flex;
  flex-direction: column;
  tab-index: 0;
}

.headline {
  font-weight: 700;
}

.title {
  font-size: 16px;
  font-weight: 600;
  min-height: 2em;
  display: flex;
  align-items: center;

  & > :global(.anticon) {
    margin-left: 8px;
  }
}

.spacer {
  flex: 1;
}

.errorMessage {
  margin-bottom: 8px;
  margin-top: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  tab-index: 0;
  gap: 0.5rem;
}

@primary-color: rgb(25,61,113);