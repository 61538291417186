/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.toolbar {
  flex: 0 0 auto;
  min-height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.toolbar :global(.ant-menu-horizontal) {
  line-height: 39px;
}
.toolbar.padding {
  padding: 0 0.5rem;
}
.toolbar.styled {
  background-color: #ffffff;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  border-bottom: 1px solid #e6e8f1;
}
.block {
  flex: 1;
}
.docsToolbarLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 1 auto;
  min-width: 0;
  flex-wrap: wrap;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  max-width: 100%;
  min-width: 1rem;
}
.docsToolbarRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 auto;
  min-width: 0;
}
.flex {
  flex: 1 0 0;
}
