/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
}
.title {
  margin-bottom: 0.5rem;
}
