.splitPane {
  position: relative !important;
  height: 100% !important;
  & :global(.Pane) {
    min-width: 0;
  }
}

.splitPaneMargin {
  padding: 1rem;
}

.resizer {
  z-index: 10 !important;
  position: relative;
}

.container {
  width: 100%;
  height: 100%;
}

.scrollable {
  height: auto;
}

.scrollablePane1 {
  & > :global(.Pane.Pane1) {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: @primary-color;
      background-clip: padding-box;
      border: 4px solid rgba(0, 0, 0, 0);
      border-radius: 8px;
      -webkit-border-radius: 8px;
      box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: @primary-color;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
}

.scrollablePane2 {
  & > :global(.Pane.Pane2) {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: @primary-color;
      background-clip: padding-box;
      border: 4px solid rgba(0, 0, 0, 0);
      border-radius: 8px;
      -webkit-border-radius: 8px;
      box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: @primary-color;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
}

.hidePanel2 {
  & :global(.Pane.Pane2) {
    display: none;
  }
  & :global(.Pane.Pane1) {
    width: 100% !important;
  }
}

@primary-color: rgb(25,61,113);