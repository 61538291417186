.button {
  margin-top: 1rem;
  display: flex;
}

.alert {
  flex: 1;
  margin: 0 0 auto;
}

@primary-color: rgb(25,61,113);