.inputWrapper {
  display: flex;
  flex-direction: column;
}

.inputErrorBorder {
  border-color: red;
}

.errorWrapper {
  position: relative;
}

.errorMessage {
  position: absolute;
  min-width: 250px;
}

@primary-color: rgb(25,61,113);