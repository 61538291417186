.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scrollable {
  composes: scrollbar from global;
  overflow: auto;
  margin: 0;
  width: 100%;
  flex-grow: 1;
  padding: 1rem;
}

@primary-color: rgb(25,61,113);