.filterWrapper {
  display: flex;
  justify-content: space-between;
}

.holidayWrapper {
  max-height: 350px;
  overflow: hidden;
}

@primary-color: rgb(25,61,113);