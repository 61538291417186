.container {
  align-items: center;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  justify-content: flex-end;
  display: inline-flex;
  text-align: right;
}

.inline {
  display: inline-flex;
  flex-wrap: nowrap;
}

.containerLeft {
  justify-content: flex-start;
  text-align: left;
}

.arrow {
  font-size: 0.8em;
  margin-left: 0.4em;
  margin-right: 0.4em;
}

.root {
  margin-right: 0.4em;
}

.part {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@primary-color: rgb(25,61,113);