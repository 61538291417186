.stateOption {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.secondaryPanelIcon {
  margin-right: 4px;
}

.primaryFileError {
  text-align: 'center';
  color: 'red';
  margin-top: 6;
}

@primary-color: rgb(25,61,113);