.headerWithLink {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.section {
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.path {
  font-weight: bold;
}

.message {
  margin-bottom: 1rem;
}

.userInfoSection {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  & > * {
    flex: 1;
  }
}

.infoItem {
  margin-bottom: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
}

.infoValue {
  font-size: 1rem;
}

.linkToSettings {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.highlight {
  background: #fff7d7;
  font-weight: 700;
}

.highlightExample {
  padding: 0.1rem;
  margin: -0.1rem 0;
  display: inline-block;
}

@primary-color: rgb(25,61,113);