/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.wrapper {
  display: flex;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}
.container.labelOnLeft {
  flex-direction: row-reverse;
}
.container :global(.ant-switch-checked) {
  background-color: #193d71;
}
.container.disabled :global(.ant-switch-checked) {
  background-color: #666666;
}
.label {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  cursor: pointer;
}
