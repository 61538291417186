/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.viewer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.thumbnail {
  height: 80px;
  width: 80px;
  align-self: stretch;
  flex: 0 0 auto;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.thumbnail.staged:after {
  outline-color: #05c05c;
}
.thumbnail.warning:after {
  outline-color: #faad14;
}
.thumbnail :global(.anticon-file-unknown) {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumbnail button {
  display: none;
  position: absolute;
  right: 2px;
  top: 2px;
  color: #f5222d;
  transform: scale(0.9);
}
.thumbnail:after {
  content: '';
  outline: solid 3px transparent;
  outline-offset: -2px;
  border-radius: 0.25rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  z-index: 10;
  transition: outline-color 0.3s ease-in-out;
}
.thumbnail:not(.staged):hover:after {
  outline-color: #193d71;
}
.thumbnail:hover button {
  display: block;
}
.attachButton {
  flex: 0 0 auto;
  align-self: stretch;
}
.stagedWrapper {
  width: 100%;
  height: 100%;
}
