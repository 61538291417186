/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.leftStrip {
  position: relative;
}
.leftStrip:before {
  content: '';
  display: block;
  position: absolute;
  background: #193d71;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 50%;
  left: 3px;
  border-radius: 3px;
}
.dropdown {
  margin-left: 8px;
}
.dashboardTile {
  background: #ffffff;
  box-shadow: 0 4px 14px 0 #e5e5e5;
}
.dashboardTileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-bottom: solid 1px #e5eced;
  position: relative;
}
.projectsTileContent {
  padding: 12px;
}
.dashboardTitle {
  font-size: 0.9rem;
  font-weight: 600;
  display: block;
}
