/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.header {
  display: flex;
  background: #162740;
  justify-content: space-around;
  padding-top: 2rem;
  padding-inline: 0.5rem;
  border-radius: 0.25rem 0.25rem 0 0;
}
.header .headline {
  background: #fff;
  padding: 3rem 2rem 2rem;
  margin: 0 0.2rem;
  border-radius: 5px 5px 0 0;
  max-width: 680px;
  width: 100%;
  text-align: center;
}
.body {
  display: flex;
  justify-content: space-around;
  padding-bottom: 1rem;
  background: #e5e5e5;
  border-radius: 0 0 0.25rem 0.25rem;
}
.body .content {
  background: #fff;
  padding: 2rem 2rem 3rem;
  margin: 0 0.2rem;
  border-radius: 0 0 5px 5px;
  max-width: 680px;
  width: 100%;
}
.description {
  display: flex;
}
.links {
  overflow-wrap: break-word;
  text-indent: -1rem;
  margin-left: 1rem;
}
