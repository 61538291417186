/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.messageContainer {
  padding: 2rem;
  overflow: hidden;
}
.headers tr > td {
  padding: 0.7rem 16px;
}
