.masterItem {
  min-width: 200px;
  display: flex;
  position: relative;
  flex: 1;
  justify-content: stretch;

  &.noFlex {
    flex: 0;
  }

  &.showFirstPart:first-child {
    flex: 1;
  }

  &.hideItem {
    display: none;
    overflow: hidden;
    flex: 0;
  }
}

.visibleItem {
  width: 100%;
}

.wrapper {
  background: #f1f1f1;
}

@primary-color: rgb(25,61,113);