.restoreDocumentsWrap {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}

.restoreDocumentsTitle {
  display: block;
  margin-right: 0.5rem;
}

@primary-color: rgb(25,61,113);