/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.content {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.breadcrumbLink {
  cursor: pointer;
  color: #193d71;
}
.unitSelect {
  width: 300px;
  max-width: 100%;
}
.reportWrapper :global(.dx-header-row) {
  background-color: #e8ebfa !important;
  color: black !important;
}
.reportWrapper :global(.dx-header-row) .centered {
  text-align: center !important;
}
.reportWrapper :global(.dx-data-row) .kpiCell {
  padding: 0 !important;
}
.content {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.formItem {
  width: 300px;
  max-width: 100%;
  text-align: left;
}
.submit {
  width: 100%;
}
.note {
  justify-content: space-between;
  width: 100%;
}
:global(.dx-data-row) td.noMargin {
  padding: 0 !important;
}
:global(.dx-data-row) td.noMargin > div.cellBorder {
  padding: 5px 8px !important;
}
:global(.dx-data-row) td.noMargin .validationError {
  background-color: #ff7875;
}
.flexTitle {
  display: flex;
  justify-content: space-between;
  height: auto;
}
.viewSelect {
  margin-right: 0.5rem;
  width: 180px;
}
.filterToolbar {
  flex: 0 1;
  margin-bottom: 1rem;
}
.expandColumnButton {
  position: absolute;
  left: 0;
}
.saveViewButton {
  margin-right: 1rem;
}
.editViewButton {
  margin-right: 0.5rem;
}
.foldableMonths :global(.dx-treelist-text-content) {
  position: relative;
  width: 100%;
  padding-left: 24px;
}
.editButton {
  width: 18px !important;
  height: 18px !important;
}
.buttonToolbar > * {
  margin-bottom: 0.5rem;
}
