/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.title {
  margin: 24px 48px 0 48px;
}
.projectCard:hover {
  transform: scale(1.06);
}
.sider {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}
.sider :global(.ant-layout-sider-children) {
  display: flex;
  flex-direction: column;
}
.sider::-webkit-scrollbar {
  display: none;
}
.menu {
  flex: auto;
}
.subMenu {
  padding-left: 48px !important;
  line-height: 30px !important;
  height: 30px !important;
}
.menuBottom :global(.ant-menu-item):not(:last-child) {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  margin: 0 !important;
}
.menuItemCompany {
  margin-top: 24px !important;
  margin-bottom: 16px !important;
  font-size: 12px !important;
}
.layoutContent {
  display: flex;
  flex-direction: column;
}
.breadcrumbs {
  padding: 8px 24px;
}
.content {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.results {
  flex: 1 auto;
  overflow: hidden;
}
.mainContent {
  margin-left: 200px;
  transition: margin-left 0.2s;
}
.mainContent.collapsed {
  margin-left: 80px;
}
.scrollableContent {
  height: auto;
  display: block;
  overflow: auto;
  composes: scrollbar from global;
}
.scrollableContentInner {
  height: auto;
  display: block;
  overflow: hidden;
  background: #ececec;
}
.projectsTile {
  margin: 1rem;
}
