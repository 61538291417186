/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.toolbar {
  display: flex;
}
.pathContainer {
  min-width: 0;
  flex: 1;
}
.item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  white-space: nowrap;
  padding-left: 0.5rem;
  font-weight: 500;
}
.item.hasLink {
  color: inherit;
}
.item .strong {
  font-weight: 700;
  cursor: pointer;
}
.item.overflow:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item.overflow:first-child .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item .link {
  color: #193d71;
  text-decoration: none;
  cursor: pointer;
}
.item .link:hover {
  text-decoration: underline;
}
