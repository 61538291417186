.table {
  margin-bottom: 8px;
}
.list {
  max-height: 500px;
}
.pagination {
  padding: 0.7rem 1rem;
}

@primary-color: rgb(25,61,113);