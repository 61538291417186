/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.menu {
  background: transparent;
}
.menu :global(.ant-menu-inline) {
  background: transparent !important;
}
.menu :global(.ant-menu-item) {
  border-radius: 0.25rem;
  background: #ffffff !important;
}
.menu :global(.ant-menu-item)::after {
  top: 3px;
  bottom: 3px;
  border: none;
  width: 4px;
  right: 3px;
  border-radius: 2px;
  background: #193d71;
}
.menu :global(.ant-menu-item:hover) {
  background: #ffffff !important;
  color: #193d71 !important;
  text-decoration: underline;
}
.menu :global(.ant-menu-item-selected) {
  background: #ffffff !important;
  color: #193d71 !important;
  font-weight: 700;
}
.menu :global(.ant-menu-item-selected)::after {
  top: 3px;
  bottom: 3px;
  border: none;
  width: 4px;
  right: 3px;
  border-radius: 2px;
  background: #193d71;
}
