.modal {
  text-align: center;
}

.icon {
  margin-right: 16px;
}

.error {
  margin-top: 16px;
  text-align: left;
}

@primary-color: rgb(25,61,113);