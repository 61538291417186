.viewer {
  background-color: #f1f1f1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > video {
    max-width: 100%;
    max-height: 100%;
  }

  & > audio {
    width: 100%;
  }

  & > .beforeViewContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  & .infoBox {
    max-width: 400px;
    margin-top: 1rem;
    text-align: center;
  }
}

@primary-color: rgb(25,61,113);