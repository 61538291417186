.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  min-height: 0;
}
.contentWrapper {
  flex: auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
  min-height: 0;
}

@primary-color: rgb(25,61,113);