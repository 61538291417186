.wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
  background: #f1f1f1;
  gap: 1rem;
}

.menu {
  display: flex;
  width: 200px;
  composes: scrollbar from global;
  overflow: auto;

  :global(.ant-menu-inline) {
    background: transparent !important;
  }
  :global(.ant-menu-item) {
    border-radius: 0.25rem;
    &::after {
      top: 3px;
      bottom: 3px;
      border: none;
      width: 4px;
      right: 3px;
      border-radius: 2px;
      background: @primary-color;
    }
  }

  :global(.ant-menu-item:hover) {
    background: #ffffff !important;
    color: @primary-color !important;
    text-decoration: underline;
  }

  :global(.ant-menu-item-selected) {
    background: #ffffff !important;
    color: @primary-color !important;
    font-weight: 700;
    &::after {
      top: 3px;
      bottom: 3px;
      border: none;
      width: 4px;
      right: 3px;
      border-radius: 2px;
      background: @primary-color;
    }
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 1rem 0 0.5rem 0;
  background: transparent;
  & h1 {
    padding-left: 16px;
  }
}

.mainTitle {
}

.content {
  display: flex;
  flex: 1;
  overflow-x: auto;
  composes: scrollbar from global;
}

.item {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.content .item {
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #e8e8e8;
  &:first-child {
    padding-left: 0;
    border-left: none;
  }
}

.itemToolbar {
  outline: none;
  margin-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
}

.itemTabs {
  display: flex;
  flex-direction: column;
}

.itemContent {
  composes: scrollbar from global;
  overflow-y: auto;
  padding: 8px;
}

@primary-color: rgb(25,61,113);