/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.header {
  overflow-x: auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #162740;
}
.logo {
  margin: auto;
}
.content {
  max-width: 800px;
  margin: 30px auto 30px;
  padding: 20px;
  display: flex;
  flex: 1;
}
.spin {
  margin-top: 48px;
  margin-left: 220px;
}
.download {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.pin {
  flex: 1;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.row {
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  max-width: 540px;
}
@media screen and (max-width: 400px) {
  .row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.name {
  flex: 1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.wrapper {
  min-height: 200px;
}
.table {
  max-width: initial;
}
.error {
  max-width: 100%;
}
.label {
  min-width: 100px;
  margin-right: 5px;
}
.value {
  margin-left: 5px;
}
