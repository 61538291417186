/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.checkbox {
  display: flex;
  cursor: pointer;
  position: relative;
  left: 0;
  top: 0;
  width: 30px;
  bottom: 0;
  min-height: 32px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 0 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox:before {
  content: '';
  display: flex;
  left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  border: solid 2px #b3b3b3;
  background: #ffffff;
  color: #b3b3b3;
  font-weight: 900;
}
.checkbox.semiSelected.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox.semiSelected:before {
  display: block;
  color: #ffffff;
  border: solid 4px #193d71;
}
.checkbox.selectedCheckbox.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox.selectedCheckbox:before {
  display: block;
  color: #ffffff;
  background: #193d71;
  border: solid 2px #ffffff;
}
.checkbox.disabled:before {
  color: #ffffff;
  background: #b3b3b3;
  border: solid 2px #999999;
  cursor: not-allowed;
}
