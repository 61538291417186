.stateOption {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.secondaryFileIcon {
  margin-right: 4px;
}

.documentCreateFormModal {
  min-width: 320px;
  width: 1200px !important;
  max-width: calc(100% - 32px);
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.mainSection {
  flex: 1;
  min-width: 280px;
  max-width: 100%;
}

.sideSection {
  flex: 0 0 320px;
  max-width: 100%;
}

@media (max-width: 900px) {
  .wrapper {
    flex-direction: column;
  }

  .sideSection {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .sideSection > * {
    flex: 1;
    min-width: 300px;
  }
}

@primary-color: rgb(25,61,113);