/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.roleInfo {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.3rem;
  overflow: auto;
  min-width: 200px;
}
.roleUserIcon {
  display: flex;
  position: relative;
}
.name {
  flex: 1;
  line-height: 1.1em;
  margin-left: 0.5rem;
  overflow: hidden;
  min-width: 4rem;
  display: flex;
  flex-direction: column;
}
.usernameContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -0.3em;
  line-height: 1.3rem;
  position: relative;
}
.withIcon {
  margin-left: 1.2rem;
}
.roleIcon {
  position: absolute;
  margin-right: 0.5rem;
  top: 0.25rem;
}
.userIcon {
  position: absolute;
  margin-right: 0.5rem;
  top: 0.25rem;
}
.emailIcon {
  position: absolute;
  margin-right: 0.5rem;
  top: 0.25rem;
}
.roleName {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3rem;
  margin-top: -0.15em;
  position: relative;
  white-space: nowrap;
}
.noUserIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: dashed 1px #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noUser {
  margin-top: -0.2em;
  font-style: italic;
  font-weight: 400;
}
.noActiveUser {
  color: red;
}
