/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.content {
  display: flex;
  white-space: nowrap;
  min-width: 4em;
  text-align: center;
  width: auto;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  cursor: pointer;
  border-radius: 0.25rem;
}
.content:hover {
  outline: solid 3px #193d71;
}
.content:has(.input) {
  outline: solid 3px #193d71;
}
.input {
  background: transparent;
  min-width: 1em;
  border: none;
  outline: none;
  padding: 0;
  max-width: 10em;
}
