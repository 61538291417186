.permission {
  & + & {
    margin-top: 1rem;
  }
}

.permissionItem {
  & + & {
    margin-top: 0.25rem;
  }
}

.switch {
  margin-right: 8px;
}

.revisionTag {
  max-width: min-content;
}

@primary-color: rgb(25,61,113);