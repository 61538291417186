/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.scrollable {
  composes: scrollbar from global;
  overflow: auto;
  margin: 0;
  width: 100%;
  flex-grow: 1;
  border: solid 1px #e5e5e5;
  background: #e5e5e5;
  padding: 0.5rem;
}
