/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.chart {
  width: 100%;
  height: 100%;
}
.chart > div {
  width: 100%;
  height: 100%;
}
.empty {
  margin-bottom: 50px;
}
@media only screen and (max-width: 500px) {
  .charts {
    display: none;
  }
}
