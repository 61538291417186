.select {
  width: 100%;

  & li:global(.ant-select-selection__choice) {
    background-color: #fff;
    border: 1px solid transparent;
    padding: 0;

    & :global(.ant-tag) {
      padding-right: 18px;
      margin-right: 0;
    }
  }
}

@primary-color: rgb(25,61,113);