.displayName {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-weight: inherit;
}

.isWrap {
  overflow: auto;
  white-space: pre-wrap;
  composes: scrollbar from global;
}

.noWrap {
  white-space: pre;
}

.strong {
  font-weight: 700;
}

@primary-color: rgb(25,61,113);