.wrapper {
  padding: 8px;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-weight: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nonActiveRole {
  color: red;
}

@primary-color: rgb(25,61,113);