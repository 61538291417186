.logo {
  --logoSize: 40px;
  color: #fff !important;
  height: var(--logoSize);
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  gap: 0.5rem;

  .img {
    border: 0;
    height: 100%;
    max-height: 100%;
    width: var(--logoSize);
    min-width: var(--logoSize);
    display: block;
    background-position: center left;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .text {
    flex: 1;
    line-height: 1;
    display: flex;
    word-break: break-word;
    white-space: normal;
  }
}

@primary-color: rgb(25,61,113);