/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.character {
  width: 40px;
  height: 17px;
  background-clip: border-box;
  display: flex;
}
:global(.ant-rate-star-zero) .characterInner,
:global(.ant-rate-star-focused) .characterInner {
  background-color: #d8d8d8;
}
.characterInner {
  flex: 1;
  margin: 4px 0;
}
.rateWrap {
  min-width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.5rem;
  flex: 1;
  min-height: 24px;
}
.rateLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.5rem;
}
.rate {
  vertical-align: middle;
  display: flex;
}
.rate > li:global(.ant-rate-star-full) .characterInner {
  background-color: #193d71;
}
.rate.coloured > li:nth-child(1):global(.ant-rate-star-full) .characterInner {
  background-color: green;
}
.rate.coloured > li:nth-child(2):global(.ant-rate-star-full) .characterInner {
  background-color: orange;
}
.rate.coloured > li:nth-child(3):global(.ant-rate-star-full) .characterInner {
  background-color: red;
}
.rate.disabled.disabled > li:global(.ant-rate-star-full) .characterInner {
  background-color: gray;
}
.rate:global(.ant-rate .ant-rate-star) > div:hover {
  transform: scale(1.1, 1.2);
}
.rate:global(.ant-rate-disabled .ant-rate-star) > div:hover {
  transform: scale(1);
}
