/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.directoryTree {
  border-radius: 0.25rem;
  background: #ffffff;
}
.dropzone {
  flex: auto;
  position: relative;
  overflow: hidden;
  display: flex;
}
.docsPane {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
  height: 100%;
}
.dirsSection {
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  composes: scrollbar from global;
}
.content {
  padding: 0;
  flex: auto;
  display: flex;
  flex-direction: row-reverse;
  min-height: 0;
}
.fileToolbar {
  background-color: #fff;
  padding: 0 0.5rem;
  margin: 0;
  border-radius: 0.25rem;
}
.directoryToolbar {
  background-color: #fff;
  padding: 0 0.5rem;
  margin: 0;
  border-radius: 0.25rem;
}
.documentGridWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
.subMenu {
  margin-right: 0.5rem;
}
.label {
  margin: 0 1rem 0.5rem 1rem;
  font-weight: 600;
}
.dirElipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
