.categoryTree {
  padding: 1rem;
  border-radius: 0.25rem;
}
.categoryTree :global(.ant-tree-node-content-wrapper) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryTreeNode {
  display: flex;
}

.categoryTreeNodeTitle {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryTreeNodeTitleActions {
  flex: 0 0 auto;
}

.deleteOptionsContainer {
  margin: 0.5rem 0;
}

@primary-color: rgb(25,61,113);