.wrap {
  height: auto;
  white-space: normal;
  line-height: 1.2;
  padding-block: 0.1rem;
  min-height: 32px;

  &:global(.ant-btn-sm) {
    min-height: 24px;
  }

  &:global(.ant-btn-lg) {
    min-height: 48px;
  }
}

@primary-color: rgb(25,61,113);