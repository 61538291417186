/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.comment {
  border-radius: 3px;
  border: solid 1px #e5e5e5;
  border-left: solid 5px #ea542d;
  margin: 1rem 0 0 0;
}
.comment .attachmentHeader {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.2rem 0 0;
}
.comment .attachmentHeader button {
  line-height: 14px;
  transform: scale(0.9);
}
.comment .message {
  padding: 0.5em;
  min-height: 2em;
}
.comment .addition {
  min-height: 2em;
  border-top: solid 1px #e5e5e5;
}
.comment .additionTitle {
  padding: 0 0 0 0.5rem;
  display: block;
}
.comment .viewer {
  margin-left: 0.5rem;
}
.emptyPlaceholder {
  font-style: italic;
  color: #999999;
  cursor: pointer;
}
.messageTextArea {
  padding: 0.5em;
  border-radius: 0 3px 3px 0;
  border: none;
  composes: scrollbar from global;
  white-space: pre-wrap;
  max-height: none !important;
}
.editable {
  composes: editable-input from global;
}
