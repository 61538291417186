.centeredContent {
  flex: 1;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ForgeViewer {
  /*flex: 1;*/
  height: 100%;
  width: 100%;
  position: relative;
}

.overlayContainer {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  gap: 0.25rem;
  z-index: 1;
  display: flex;
  flex-direction: row;
  max-width: 90%;
  align-items: flex-start;

  &:empty {
    display: none;
  }

  .bubbleNodesSelect {
    max-width: 150px;
  }
}

.loadbarOuter {
  position: absolute;
  bottom: 10px;
  left: 4px;
  width: 242px;
  height: 6px;
  background-color: #2c2c2c;
  z-index: 1;
}

.loadbarMiddle {
  background-color: black;
  margin: 1px;
  height: 4px;
  width: 240px;
}

.loadbarInner {
  height: 100%;
  background-color: red;
  border-radius: 2px;
}

.ForgeViewer .viewer {
  position: relative;
  width: 100%;
  height: 100%;
  /*top: 0;*/
  /*left: 0;*/
}

.ForgeViewer .scrim {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #ededed;
  color: #95a5a6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ForgeViewer .scrim .message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ForgeViewer .scrim .message svg {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}

.errorMessageWrap {
  margin-bottom: 1rem;
}

h4.unitTitle {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.notFoundElementsError {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  max-width: 100%;
  text-align: center;
}

@primary-color: rgb(25,61,113);