.modal {
  display: none;
  position: fixed;
  top: 5vh;
  left: 50px;
  right: 50px;
  bottom: 5vh;
  height: 90svh;
  width: 90svw;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  overflow: hidden;
  flex-direction: column;
  margin: auto;
  border-radius: 0.5rem;
  transition: width 233ms, height 200ms, left 200ms, right 200ms, box-shadow 200ms, border-radius 200ms;
}

.modal.visible {
  display: flex;
}

.modal.fullscreen {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100svh;
  width: 100svw;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
  border-radius: 0;
}

.content {
  flex: 1;
  overflow: hidden;
}

.modal :global(.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar) {
  margin-bottom: 0;
}

.header {
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 40px;
  border-bottom: solid 1px #e8e8e8;
  gap: 0.5rem;
  flex-wrap: wrap;

  & > * {
    flex: 1;
  }
}

.buttons {
  justify-content: flex-end;
}

@media screen and (max-width: 900px) {
  .modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
    border-radius: 0;
  }

  .header {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0.5rem;

    & > * {
      flex: auto;
    }
  }

  .buttons {
    justify-content: space-between;
    flex: 1;
    width: 100%;
  }
}

.title {
  display: flex;
  justify-content: center;
  flex: 3;
  align-items: center;
  min-width: 300px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

@primary-color: rgb(25,61,113);