.mask {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.45);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  right: 0;
  z-index: 99;
}

@primary-color: rgb(25,61,113);