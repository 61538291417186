.container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 1rem;
}

.pathWithoutLast {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 32px;
}

.pathLastPart {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow {
  font-size: 0.7rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.root {
  margin-right: 0.4em;
}

.simpleLink {
  display: flex;
}

.part {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    min-width: 0;
  }

  &:last-child {
    flex: 1;
    min-width: 5rem;
  }
}

.partLink {
  overflow: hidden;
  text-overflow: ellipsis;
}

@primary-color: rgb(25,61,113);