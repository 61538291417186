.icon {
  width: 1rem;
  height: 1rem;
}

.disabled {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

@primary-color: rgb(25,61,113);