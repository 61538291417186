.itemList {
  max-height: 500px;
  padding-right: 10px; // space for scrollbar
  padding-bottom: 5px; // fixes scrollbar appearing when it shouldn't
}

.item {
  border-bottom: 1px dotted #d9d9d9;
  padding-bottom: 4px;
  padding-top: 2px;
}

.itemBottom {
  padding-top: 2px;
}

.uploadStats {
  justify-content: space-between;
  margin-bottom: 1rem;
}

@primary-color: rgb(25,61,113);