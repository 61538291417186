.results {
  max-width: 300px;
}

.title {
  font-weight: bold;
  margin-bottom: 0.5em;
  word-break: break-word;
}

.tag {
  font-size: 1rem;
  font-weight: bold;
}

@primary-color: rgb(25,61,113);