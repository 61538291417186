.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.itemCounter {
  padding: 0.3rem 0.5rem;
}

@primary-color: rgb(25,61,113);