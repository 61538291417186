/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.headerSearch {
  display: flex;
  height: 0;
  min-height: 0;
  transition: height 0.3s;
  align-items: center;
  overflow: hidden;
  margin: 0;
  justify-content: center;
}
.headerSearch.show {
  height: 40px;
  margin-bottom: 0.25rem;
}
.headerSearch:global(.anticon-search) {
  font-size: 16px;
  cursor: pointer;
}
.headerSearch .input {
  min-width: 250px;
  width: 250px;
  margin-left: 8px;
  flex: 1;
}
@media (max-width: 768px) {
  .headerSearch {
    font-size: 0.9rem;
  }
}
