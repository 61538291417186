.modalWrap {
  background-color: #eee;
  padding: 0 1rem;
}

.masterPanel {
  max-width: 800px;
}

.settingsContainer {
  margin-top: 1rem;
}

.tabContent {
  & :global(.ant-tabs-content) {
    margin-top: 0.8rem;
  }
}

@primary-color: rgb(25,61,113);