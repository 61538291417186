.wrapper {
  padding: 0.3rem;
  display: flex;
  align-items: center;

  display: flex;
  flex: 1 1;
  align-items: center;
  padding: 0.3rem;
  overflow: auto;
}

.noPadding {
  padding: 0;
}

.avatar {
  margin: 0;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  line-height: 1.3em;
  margin-left: 0.5rem;
  overflow: hidden;
  min-width: 4rem;
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  .username {
    margin-left: 1.2rem;
  }
}

.description {
  font-weight: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activity {
  margin-left: auto;
}

.usernameContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -0.3em;
  line-height: 1.5em;
  position: relative;

  .username {
    margin-left: 1.2rem;
  }
}

.emailIcon {
  position: absolute;
  margin-right: 0.5rem;
  top: 0.25rem;
}

@primary-color: rgb(25,61,113);