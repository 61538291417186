/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.projectSelect {
  width: 100%;
  margin-right: 0.2rem;
}
.selectDescription {
  display: flex;
  flex-direction: column;
}
.projectWrapper {
  display: flex;
  flex-wrap: nowrap;
}
.projectRow {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e5eced;
}
.projectRowHeader {
  padding: 0.5rem 1rem 1rem;
  border-bottom: 1px solid #b3b3b3;
}
.projectRowHeader > :global(.ant-col) {
  font-weight: 600;
}
