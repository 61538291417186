/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.layoutContent {
  padding: 8px 8px 0;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  flex: auto;
  overflow: hidden;
}
.mainContent {
  height: 100vh;
}
