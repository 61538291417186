.inheritanceSwitch {
  margin-block: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.centerButton {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@primary-color: rgb(25,61,113);