.consendArea {
  height: 100%;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
}

@primary-color: rgb(25,61,113);