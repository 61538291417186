/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.status {
  white-space: nowrap;
  padding: 0 10px;
  height: 24px;
  text-align: center;
}
.statusNeW {
  color: #fa541c;
}
.statusInProgress {
  color: #1890ff;
}
.statusForApproval {
  color: #fa8c16;
}
.statusSolved {
  color: #389e0d;
}
.statusClosed {
  color: #722ed1;
}
.statusUnknown {
  color: #999999;
}
.editIcon {
  margin-left: 0.25rem;
}
.clickable {
  cursor: pointer;
}
