.empty {
  justify-self: center;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 174px;
  justify-content: center;
}

@primary-color: rgb(25,61,113);