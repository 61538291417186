/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.filterText {
  padding: 0.5rem;
}
.filterText > :global(.ant-input) {
  background: #f1f1f1 !important;
  border: none !important;
}
.extensionLabel {
  max-width: 80px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.extensionOption {
  padding: 0.5rem;
}
