.esticonIcon {
  max-width: 100%;
  max-height: 100%;
  width: 1em;

  &.monochrome {
    * {
      fill: currentColor;
    }
  }
}

@primary-color: rgb(25,61,113);