.logo {
  --logoSize: 1.2em;
  color: #fff;
  font-weight: 100;
  font-size: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;

  .name {
    flex: 1;
  }

  & > b {
    font-weight: 500;
  }
}

.icon {
  margin: auto;
  border-radius: 0.05em;
  background-color: #ffffff;
  height: var(--logoSize);
  width: var(--logoSize);
  min-width: var(--logoSize);
  display: flex;

  & > img {
    padding: 0.2em;
  }
}

@primary-color: rgb(25,61,113);