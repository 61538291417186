/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container {
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.2rem;
  overflow: hidden;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
}
