.leftStrip {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: @primary-color;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 50%;
    left: 3px;
    border-radius: 3px;
  }
}
