.spin {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;

  &.fill {
    height: 100%;

    & > :global(.ant-spin-container) {
      height: 100%;
    }
  }

  &.cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 !important;
  }
}

.children {
  margin-top: 0.5rem;
}

@primary-color: rgb(25,61,113);