.selectProject {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.selectItem {
  display: flex;
  flex-direction: column;
  & > span {
    line-height: 1.8rem;
    height: 1.5rem;
  }
}

.label {
  margin-bottom: 5px;
}

@primary-color: rgb(25,61,113);