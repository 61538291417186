.filesGrid {
  max-height: 250px;
}

.documentRow {
  margin-bottom: 0.5rem;
}

.pathDisplay {
  overflow: auto;
}

@primary-color: rgb(25,61,113);