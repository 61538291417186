.formatedTooltip {
  white-space: pre-line;
  min-width: 300px;
  max-width: 50%;
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

@primary-color: rgb(25,61,113);