/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.leftStrip {
  position: relative;
}
.leftStrip:before {
  content: '';
  display: block;
  position: absolute;
  background: #193d71;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 50%;
  left: 3px;
  border-radius: 3px;
}
.card {
  height: 360px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 900px) {
  .card {
    height: auto;
  }
}
.closeButton {
  line-height: 1rem;
}
.detail {
  padding: 8px 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.name {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  vertical-align: center;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 0.5rem;
}
.description {
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 0.4em !important;
}
.params {
  margin-bottom: 1rem;
}
.tooltip {
  width: 600px;
  max-width: 90%;
}
.projectNameTitle {
  font-weight: 600;
  margin-bottom: 1rem;
}
