.menu {
  flex: auto;
  min-width: 0;
  border-bottom: 1px solid transparent;
}

.right {
  justify-content: flex-end;
}

:global(.ant-menu-sub) .down {
  display: none;
}

:global(.ant-menu-overflow-item.ant-menu-item.ant-menu-item),
:global(.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-horizontal) {
  padding-inline: 0.5rem;
}

:global(.ant-menu-horizontal > .ant-menu-item)::after,
:global(.ant-menu-horizontal > .ant-menu-submenu)::after {
  left: 0.5rem !important;
  right: 0.5rem !important;
}

@media (max-width: 900px) {
  .menu {
    & :global(.ant-menu-title-content) {
      display: none;
    }

    :global(.ant-menu-submenu) {
      padding: 0 !important;
    }

    :global(.ant-menu-submenu .ant-menu-submenu-title) > {
      padding: 0 1rem !important;
    }

    :global(.ant-menu-overflow-item.ant-menu-item.ant-menu-item),
    :global(.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-horizontal) {
      padding-inline: 1rem;
    }
  }
}

@primary-color: rgb(25,61,113);