/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.styledBox {
  background-color: #ffffff;
  border-radius: var(--box-border-radius);
  border-bottom: 1px solid #e6e8f1;
}
.flex {
  flex: 1;
  display: flex;
  overflow: hidden;
  min-height: 0;
}
.padding {
  padding: 0.25rem 0.5rem;
}
