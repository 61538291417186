.label {
  font-weight: bold;
}

.value {
  margin-top: 0.5rem;
}

.param {
  margin-top: 0.5rem;
}

.item {
  margin-right: 1rem;
}

.main {
  margin-left: 1rem;
  overflow: auto !important;
  composes: scrollbar from global;
}

@primary-color: rgb(25,61,113);