.groupMenu {
  background-color: white;
  composes: scrollbar from global;
  overflow-y: auto;
  max-height: 400px;
}

.transferList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  > :global(.ant-transfer-list) {
    display: inline-flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    padding: 0;

    > :global(.ant-transfer-list-header) {
      position: inherit;
    }

    > :global(.ant-transfer-list-body) {
      display: inline-flex;
      flex-direction: column;
      flex: 1 1;
      overflow: hidden;
    }

    > :global(.ant-transfer-list-footer) {
      position: inherit;
    }
  }
}

@primary-color: rgb(25,61,113);