.filterToolbar {
  border-radius: 0.25rem;
  align-items: center;
  max-width: 100%;
  display: flex;
  flex: 1;
  min-width: 180px;
  gap: 0.25rem 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
}

@primary-color: rgb(25,61,113);