.container {
  display: flex;
  flex-wrap: wrap;

  & > .card {
    width: 400px;
    max-width: 600px;
    flex-grow: 1;
    margin: 12px;
    min-width: 0;
  }
}

@primary-color: rgb(25,61,113);