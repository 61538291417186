.container {
  display: flex;
  align-content: flex-start;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior-y: none;
}

.vertical {
  flex-direction: column;
}

.center {
  align-items: center;
}

.splitContent {
  justify-content: space-between;
}

.centerContent {
  justify-content: center;
}

.endContent {
  justify-content: end;
}

.stretch {
  flex: 1;
}

.paddingNormal {
  padding: 1rem;
}

.scrollable {
  overflow-y: auto !important;
  composes: scrollbar from global;
  overscroll-behavior-y: none;
}

.autoHeight {
  height: auto;
  overflow: visible;
}

.autoWidth {
  width: auto;
}

.growLast > div:last-child {
  flex-grow: 1;
}

.showOverflow {
  overflow: visible;
}

@primary-color: rgb(25,61,113);