.header {
  --headerHeight: 40px;
  background: #ffffff;
  color: @primary-color;
  padding: 0;
  height: auto;
  line-height: var(--headerHeight);
  min-height: var(--headerHeight);
  border-bottom: solid 1px #f1f1f1;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
  justify-content: center;
}

.icon {
  padding: 4px;
  vertical-align: middle;
}

.mainBar {
  max-width: 100%;
  height: var(--headerHeight);
}

.hamburger {
  margin-left: 0.5rem;
  //min-width: 40px;
  //padding: 0 0.25rem;
}

.hamburgerCollapsed {
  display: none;
}

@primary-color: rgb(25,61,113);