/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.managerForm {
  display: flex;
}
.managerForm :global .ant-select-selection.ant-select-selection--single {
  height: 48px !important;
}
.managerForm :global .ant-select-selection.ant-select-selection--single > div {
  height: 100%;
  display: flex;
  align-items: center;
}
.managerItem {
  padding: 0 0.5rem 0 0;
  border: solid 1px #e5e5e5;
  border-radius: 0.2rem;
  background: #ffffff;
}
.managerItem:not(:last-child) {
  margin-bottom: 0.5rem;
}
.managerItem:hover {
  background: #f1f1f1;
}
.roleSelect {
  margin-bottom: 1rem;
}
