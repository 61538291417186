.main {
  display: flex;
  width: 100%;
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.5rem;
  align-items: flex-start;
  display: flex;
  width: 100%;
}

@primary-color: rgb(25,61,113);