/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.accentWarning {
  color: #faad14;
}
.accentForbidden {
  color: #cf1322;
}
.accentRevision {
  color: #722ed1;
}
.description {
  font-size: 0.9rem;
  line-height: 0.9rem;
  display: block;
}
.statistic {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.statistic :global(.ant-btn-link) {
  color: inherit;
}
.statistic :global(.ant-btn-link) :global(.anticon) {
  margin: 0;
}
