.name {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 8px;
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
  a:hover {
    text-decoration: underline !important;
  }
}

@primary-color: rgb(25,61,113);