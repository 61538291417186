.content {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}

.results {
  flex: 1 auto;
  overflow: hidden;
}

@primary-color: rgb(25,61,113);