.top {
  margin-top: 1rem;
}
.left {
  margin-left: 1rem;
}
.right {
  margin-right: 1rem;
}
.bottom {
  margin-bottom: 1rem;
}

.inFlex {
  min-width: 0;
  min-height: 0;
}

@primary-color: rgb(25,61,113);