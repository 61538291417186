/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.wrapper {
  display: inherit;
  flex-direction: inherit;
  flex: 1;
  overflow: hidden;
}
.dropArea {
  z-index: 111;
  border: dashed 4px #193d71;
  background: #ffffffdb;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropArea .hint {
  text-align: center;
  margin: auto;
  font-size: 1.5rem;
}
.dropArea.hidden {
  visibility: hidden;
}
.dropArea.disabled {
  cursor: no-drop;
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
}
.input {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
