.filterList {
  display: flex;
  flex-direction: column;
  composes: scrollbar from global;
  overflow: auto;
  flex: 1;

  .filterOption {
    padding: 0.5rem 1rem;
    margin: 0;
  }
}

.filterDropDownHeader {
  border-bottom: solid 1px #f1f1f1;
  display: flex;
  flex-direction: column;
}

.searchInput {
  padding: 0.5rem;
  & > :global(.ant-input) {
    background: #f1f1f1 !important;
    border: none !important;
  }
}

.firstSelected {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  padding: 6px 16px;
  font-weight: 700;
  max-width: 320px;
}

@primary-color: rgb(25,61,113);