/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
@import '~antd/es/upload/style/index.css';
@import '~antd/es/transfer/style/index.css';
:root {
  --box-border-radius: 0.25rem;
}
:focus {
  outline: 0;
}
.ant-menu-item {
  transition: none;
}
.ant-menu-submenu-title {
  transition: none;
}
.ant-table-column-sorter .anticon-caret-up {
  font-size: 16px;
}
.ant-table-column-sorter .anticon-caret-down {
  font-size: 16px;
}
.ant-breadcrumb-link > a > .anticon + span {
  margin-left: 4px;
}
/* SplitPane  */
.Resizer {
  background: #e6e8f1;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
/* Custom styled scrollbar  */
.scrollbar::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #193d71;
  background-clip: padding-box;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #193d71;
}
.scrollbar::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}
.ant-tree.ant-tree-show-line.hide-file-icon li span.ant-tree-switcher-noop {
  background: transparent;
}
.ant-tree.ant-tree-show-line.hide-file-icon li:before {
  content: ' ';
  width: 1px;
  border-left: 1px solid #d9d9d9;
  height: 100%;
  position: absolute;
  left: 12px;
  top: 0;
  margin: 0;
}
.ant-tree.ant-tree-show-line.hide-file-icon li:first-child:before {
  top: 6px;
  height: calc(100% - 6px);
}
.ant-tree.ant-tree-show-line.hide-file-icon li:last-child:before {
  height: 16px;
}
.ant-tree.ant-tree-show-line.hide-file-icon li:first-child:last-child:before {
  height: 10px;
}
.ant-tree.ant-tree-show-line.hide-file-icon li .ant-tree-switcher-noop > i {
  visibility: hidden;
}
.ant-tree.ant-tree-show-line.hide-file-icon li .ant-tree-switcher-noop:after {
  content: ' ';
  height: 1px;
  border-bottom: 1px solid #d9d9d9;
  width: 10px;
  position: absolute;
  left: 12px;
  top: 50%;
  margin: 0;
}
.Pane {
  min-width: 0;
}
.ant-tag > a:first-child:last-child {
  margin: 0;
  padding: 0;
}
.editable-input {
  border: solid 1px transparent !important;
}
.editable-input:hover,
.editable-input:focus {
  border: solid 1px #193d71 !important;
}
