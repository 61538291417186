.wrap {
  overflow: auto;
  composes: scrollbar from global;
  margin: 0 auto;
}
.box {
  max-width: 500px;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.info {
  font-size: 18px;
  margin: 36px 0;
  text-align: center;
}

.message {
  margin-bottom: 16px;
  border-left: 10px solid #dfe1e5;
  padding-left: 8px;
}

.accept {
  text-align: center;
  font-weight: 700;

  & > button {
    margin-top: 16px;
  }
}

.projectName {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@primary-color: rgb(25,61,113);