.list {
  composes: scrollbar from global;
  overflow: auto !important;

  &:focus {
    outline: none;
  }

  &:global > * {
    overflow: unset !important;
  }
}
