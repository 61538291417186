/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.wrapper {
  padding: 2px 0;
}
.row {
  height: 100%;
}
.row .thumbnail {
  width: 55px;
  align-self: stretch;
  margin-right: 1rem;
  flex: 0 0 auto;
  cursor: pointer;
}
.row .thumbnail :global(.anticon) {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row .thumbnail:hover {
  outline: solid 1px #193d71;
}
.row .linkIcon {
  margin-right: 0.3rem;
}
.row .activeLink {
  color: #193d71;
}
.row .new .main {
  position: relative;
}
.row .new .main .nameLabels .name {
  font-weight: 900;
}
.row .new .main:before {
  content: '+';
  font-size: 1.5em;
  font-weight: 700;
  color: #193d71;
  margin-right: 4px;
  position: absolute;
  top: -0.4em;
  left: -0.7em;
  line-height: 1em;
}
