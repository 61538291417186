/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  text-align: center;
}
.box :global(.anticon) {
  font-size: 120px;
}
.box :global(.anticon).loading {
  color: #1890ff;
}
.box :global(.anticon).check {
  color: #52c41a;
}
.box :global(.anticon).close {
  color: #fa541c;
}
.status {
  font-size: 24px;
  font-weight: 400;
  margin-top: 16px;
}
