.box {
  position: relative;
  background-color: rgb(225,226,222);
  background: linear-gradient(0deg, rgba(225,226,222,1) 0%, rgba(217,225,234,1) 100%);


  & .spin {
    width: 100%;
    height: 100%;

    & > :global(.ant-spin-container) {
      height: 100%;
    }
  }

  & img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@primary-color: rgb(25,61,113);