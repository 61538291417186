/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.collapse {
  border-top: 1px solid #e8e8e8;
  height: 100%;
}
.collapse :global(.ant-collapse-content-box) {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.panel {
  padding: 0 !important;
  margin: 0;
  background: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.panel :global(.ant-collapse-content-active) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.menuLabel {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.menuLabel > :global(.ant-menu-title-content) {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-grow: 1;
}
.projectName {
  flex-grow: 1;
}
