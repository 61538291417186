.iconBox {
  height: 60px;
  margin: 0;
  background-color: #e7eeff;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  & :global(.anticon) {
    font-size: 36px;
    margin-top: 2px;
  }
}

.nameRow {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  min-height: 2.125rem;
  gap: 0.5rem;
  min-width: 0;

  color: #000000;
  font-size: 20px;
  font-weight: 600;
  line-height: 2rem;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  min-width: 150px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
  align-items: center;
}

.header {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 100%;

  &.border {
  }

  &.minHeight {
    min-height: 3rem;
  }

  &.alignTop {
    align-items: flex-start;
  }

  &.paddingLeft {
    padding-left: 1rem;
  }

  &.paddingNormal {
    padding: 0.5rem 0 0.5rem 1rem;
  }

  &.paddingAll {
    padding: 0.5rem 1rem;
  }

  &.marginBottom {
    margin-bottom: 0.5rem;
  }
}

@primary-color: rgb(25,61,113);