.container {
  padding: 0;
}

.assignmentName {
  flex-grow: 1;
  font-size: 1.2em;
  font-weight: bold;
}

.ellipsis {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.headerStack {
  align-self: flex-start;
}

.itemsContainer {
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.projectName {
  margin: 0 1rem 0.5rem;
}

@primary-color: rgb(25,61,113);