.addButton {
  margin: 0.2rem auto 0.5rem;
}

.itemRow {
  margin-bottom: 0;
}

.treeSelectInput {
  display: block;
}

@primary-color: rgb(25,61,113);