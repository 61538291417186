/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.titlePanel {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  min-width: 0;
  gap: 0.5rem;
}
.docsToolbarTitle {
  flex: 1 0 0;
  min-width: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}
