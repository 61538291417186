.leftStrip {
  position: relative;
}
.leftStrip:before {
  content: '';
  display: block;
  position: absolute;
  background: #193d71;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 50%;
  left: 3px;
  border-radius: 3px;
}
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.title {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  position: relative;
}
.title:before {
  content: '';
  display: block;
  position: absolute;
  background: #193d71;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 50%;
  left: 3px;
  border-radius: 3px;
}
.titleText {
  margin-left: 15px;
  margin-top: 8px;
  min-width: 0;
  margin-right: 0.5rem;
  flex: 1;
}
.title.draggable {
  cursor: move;
}
.menu {
  align-self: center;
  margin-right: 5px;
  margin-left: auto;
}
.content {
  flex-grow: 1;
  min-height: 0;
}
.link {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
