/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.rangeInput {
  width: 580px;
  display: flex;
  justify-content: space-between;
}
.cellInRange {
  background-color: #e5e5e5;
}
