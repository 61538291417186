.list {
  composes: scrollbar from global;
  overflow: auto !important;
}
.list:focus {
  outline: none;
}
.list:global > * {
  overflow: unset !important;
}
.wrapper {
  flex: 1;
  height: 100%;
  margin-top: 0.5rem;
}
