.labelsWrap {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: flex-start;
  margin-right: 0.5rem;
  min-width: 60px;
}

.labels {
  flex: 1;
  display: flex;
  overflow: hidden;
  gap: 0.2rem 2px;
}

.overlay {
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.2rem;
  overflow: hidden;
  padding: 0.5rem;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
}

.overlayInner {
  display: flex;
  flex-wrap: wrap;
  max-width: 200px;
}

.modeTags {
  cursor: pointer;
}

.label {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  & > * {
    display: initial !important;
  }
}

@primary-color: rgb(25,61,113);