.title {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  min-height: 2rem;
}

@primary-color: rgb(25,61,113);