.flowLayout {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 0.5rem;

  &.wrap {
    flex-shrink: initial;
    flex-wrap: wrap;

    & > * {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
    }
  }

  &.growFirst {
    & > *:first-child {
      flex-grow: 1;
    }
  }

  &.growLast {
    & > *:last-child {
      flex-grow: 1;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  .gapSmall {
    gap: 0.25rem;
  }

  .gapNormal {
    gap: 0.5rem;
  }

  .gapMediumLarge {
    gap: 0.75rem;
  }

  .gapLarge {
    gap: 1rem;
  }

  .gapExtraLarge {
    gap: 2rem;
  }

  .gapNone {
    gap: 0;
  }
}

.alignLeft {
  justify-content: flex-start;
}

.alignRight {
  justify-content: flex-end;
  margin-left: auto;
}

@primary-color: rgb(25,61,113);