/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.rowHighlighted {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  padding-left: 0.3rem;
  margin-left: -0.3rem;
  padding-right: 0.3rem;
  margin-right: -0.3rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: 0.2rem;
}
.label {
  flex: 1 0;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
}
.delete {
  width: 24px;
  text-align: center;
}
.labelButtonWrap {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: nowrap;
}
.rowContainer {
  background-color: white;
  margin-block: 0.5rem;
  padding: 0.5rem 0.3rem 0.1rem 0.3rem;
  border-radius: 0.2rem;
}
.rowContainer:hover {
  background-color: #b3b3b3;
}
