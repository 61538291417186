/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container {
  display: flex;
  flex-direction: column;
  tab-index: 0;
  border-radius: var(--box-border-radius);
  background-color: #f1f1f1;
  border-bottom: 1px solid #e6e8f1;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
}
.container :global(.ant-form-item) {
  margin-bottom: 0;
}
