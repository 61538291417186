/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.projectName {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 1 auto;
  min-width: 60px;
  padding: 0 1em;
  margin: 0.25rem 0;
  cursor: pointer;
  background: #e5eced;
  border-radius: 0.5rem;
  line-height: 1.75em;
}
@media (max-width: 768px) {
  .projectName {
    font-size: 0.9rem;
  }
}
.projectNameTitle {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.projectDescription {
  margin-top: 0.2rem;
}
.tooltip {
  width: auto;
  max-width: 90%;
}
