.title {
  padding: 6px 16px;
  font-weight: 700;
  max-width: 320px;
}

.typeSelect {
  padding: 0.5rem 1rem;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@primary-color: rgb(25,61,113);