/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.tree {
  composes: scrollbar from global;
}
.tree :global(.ant-tree-draggable-icon) {
  opacity: 1 !important;
  display: none;
}
.tree :global(.ant-tree-treenode-selected) > * {
  background-color: #193d71 !important;
  color: #fff;
}
.dropdown {
  display: inline-block;
  width: calc(100% - 30px);
}
.title {
  display: flex;
  align-items: center;
  min-height: 24px;
  font-weight: 600;
}
.titleLeft {
  flex: 1;
  display: flex;
  gap: 0.5rem;
}
.linkTooltipSmall :global(.ant-tooltip-inner) {
  width: 300px;
}
.linkTooltipMedium :global(.ant-tooltip-inner) {
  width: 500px;
}
.linkTooltipLarge :global(.ant-tooltip-inner) {
  width: 700px;
}
