.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: inline-flex;
  cursor: pointer;

  &.default {
    & .color {
      height: 20px;
    }
    & .refresh {
      margin-top: 3px;
    }
  }

  &.small .color {
    height: 14px;
  }
}

.color {
  width: 36px;
  border-radius: 2px;
}

.refresh {
  margin-left: 5px;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@primary-color: rgb(25,61,113);