/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.newNote {
  margin-right: 0.5rem;
}
.message {
  margin: 0;
  padding: 0;
  color: #000000;
  white-space: pre-wrap;
  composes: scrollbar from global;
  max-height: 200px;
  overflow: auto;
}
.commentInput {
  margin: 0.3rem 0 0;
  display: flex;
  justify-content: space-between;
}
.commentInput > textarea {
  margin-right: 0.5rem;
}
