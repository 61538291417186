.wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.menu {
  display: flex;
  width: 200px;
  composes: scrollbar from global;
  overflow: auto;
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 1rem 0 0.5rem 0;
  & h1 {
    padding-left: 16px;
  }
}

@primary-color: rgb(25,61,113);