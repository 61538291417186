/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.scrollableContent {
  height: auto;
  display: block;
  overflow: auto;
  composes: scrollbar from global;
}
.projectsButton {
  text-align: center;
}
.reportContainer {
  margin-top: 1rem;
}
