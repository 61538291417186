/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.row {
  height: 100%;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  border: 0;
  border-bottom: solid 1px #b3b3b3;
  transition: 300ms background;
  background: #ffffff;
  gap: 0.5rem;
}
.row.selected {
  background: #e8e8e8;
}
.row:hover {
  background: #e5e5e5;
}
.row:hover .checkbox:before {
  display: flex;
}
.main {
  display: flex;
  width: 100%;
  align-items: center;
  min-width: 0;
  flex: 1;
}
.selectable {
  cursor: pointer;
}
