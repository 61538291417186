/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.documentsHeader {
  padding: 0.25rem 0.5rem;
}
.documentsHeader.noPadding {
  padding: 0;
}
.documentsHeader.marginBottom {
  margin-bottom: 0.5rem;
}
