/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.screen {
  width: 100vw;
  height: 100vh;
  background-color: #e6f7ff;
  text-align: center;
  padding-top: calc((100vh / 2) - 30px);
}
