.item {
  margin-bottom: 0;
}

.movingDirectoryWrap {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}

.movingDirectoryTitle {
  display: block;
  margin-right: 0.5rem;
}

.movingDirectoryPath {
  flex: 1;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@primary-color: rgb(25,61,113);