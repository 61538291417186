.spinWrapper {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;

  & > :global(.ant-spin-container) {
    height: 100%;
    flex: 1;
  }

  &.flex > :global(.ant-spin-container) {
    display: flex;
  }

  &.flex.vertical > :global(.ant-spin-container) {
    flex-direction: column;
  }

  &.overflowVisible {
    overflow: visible !important;
    :global(.ant-spin-container) {
      overflow: visible !important;
    }
  }
}

.spin {
  max-height: none !important;
}

@primary-color: rgb(25,61,113);