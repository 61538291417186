.table {
  margin-bottom: 8px;
}

.gridBody {
  overflow: auto;
  min-width: 800px;
}

.highlighted {
  outline: 3px solid #424f95;
  margin: 3px;
}

@primary-color: rgb(25,61,113);