.icon {
  width: 48px;
  color: #08c;
}

.toolbar {
  padding: 0 !important;
}

.documentInfo {
  height: 60px;
}

@primary-color: rgb(25,61,113);