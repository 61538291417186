.thumbnail {
  width: 55px;
  align-self: stretch;
  margin-right: 1rem;
  flex: 0 0 auto;

  border-radius: 0.25rem;
  overflow: hidden;

  & :global(.anticon) {
    font-size: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    outline: solid 1px @primary-color;
  }
}

@primary-color: rgb(25,61,113);