/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.content {
  height: 100%;
  border-radius: 0.25rem;
  background: #e5e5e5;
}
.empty,
.error {
  margin-top: 40%;
  text-align: center;
}
