/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.content {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.breadcrumbLink {
  cursor: pointer;
  color: #193d71;
}
.unitSelect {
  width: 300px;
  max-width: 100%;
}
.reportWrapper :global(.dx-header-row) {
  background-color: #e8ebfa !important;
  color: black !important;
}
.reportWrapper :global(.dx-header-row) .centered {
  text-align: center !important;
}
.reportWrapper :global(.dx-data-row) .kpiCell {
  padding: 0 !important;
}
