.icon {
  width: 48px;
  color: #08c;
}

.richTextEditor {
  height: 400px;
  margin-bottom: 2rem;
  line-height: 1.5rem;
}

@primary-color: rgb(25,61,113);