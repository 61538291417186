/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.error {
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
}
.error::placeholder {
  color: #ff4d4f !important;
  opacity: 0.5;
}
.input {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  border-color: transparent;
  text-overflow: ellipsis;
  margin: 0.1rem -0.5rem;
  padding: 0 0.5rem;
  min-width: 150px;
  border-radius: 0.25rem;
}
.input:hover {
  border-color: #b3b3b3;
}
.input:focus {
  border-color: #193d71;
}
.input::placeholder {
  font-style: italic;
  min-height: 1em;
  min-width: 3em;
}
.input input {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
}
.descriptionWrapper {
  position: relative;
  margin: 0 -0.5rem;
  height: 32px;
  width: 100%;
}
.descriptionWrapper > :global(.ant-input) {
  height: 32px;
}
.textarea {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  border-color: transparent;
  text-overflow: ellipsis;
  resize: none;
  border-radius: 0.25rem;
}
.textarea:hover {
  border-color: #b3b3b3;
}
.textarea:focus {
  border-color: #193d71;
  height: 6rem;
  position: absolute;
  z-index: 10;
}
.textarea.disabled {
  cursor: initial !important;
  border-color: transparent !important;
}
.textarea::placeholder {
  font-style: italic;
  min-height: 1em;
  min-width: 3em;
}
.container {
  position: relative;
}
.flex {
  flex: 1;
}
.formatedTooltip {
  white-space: pre-line;
  min-width: 250px;
  max-width: 30%;
}
